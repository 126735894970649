export default {
  setAggs(state, payload) {
    state.aggs = payload.aggs.all_filters
  },
  setFilter(state, payload) {
    if (payload.push) state.values[payload.type].push(payload.filter)
    else state.values = Object.assign(state.values, payload)
  },
  setFilters(state, payload) {
    state.values = payload
  },
  toggleMenu(state, payload) {
    state.activeMenu = payload
  },
  clearMenu: state => {
    state.activeMenu = null
  },
  clearKeyword(state, payload) {
    state.values.keyword.splice(payload.index, 1)
  },
  clearFilters: (state, payload) => Object.assign(state.values, payload)
}
