export default () => ({
  loading: false,
  listings: null,
  config: {
    scroll: false,
    total: null
  },
  onSale: null,
  sortBy: {
    type: 'daysOnSale',
    order: 'desc',
    label: 'Newest'
  }
})
