import { node } from '@/plugins/axios'

export default {
  async sortAndFetch({ commit, dispatch }, sortBy) {
    commit('setSortBy', { ...sortBy })
    try {
      await dispatch('clearAndFetch')
    } catch (err) {
      throw err
    }
  },
  async clearAndFetch({ commit, dispatch }, parameters) {
    commit('setScroll', { scroll: false })
    commit('setListings', { listings: null })
    commit('setTotal', { total: null })
    commit('setOnSale', null)
    try {
      await dispatch('fetch', parameters)
    } catch (err) {
      throw err
    }
  },
  async fetch({ commit, state, getters }) {
    commit('toggleLoading')
    const { request } = getters

    try {
      const { data } = await node.post('/listings/similar', request)

      const { listings, aggs, scroll, isScroll, total } = data

      if (scroll) commit('setScroll', { scroll })

      if (isScroll) {
        state.listings === null
          ? commit('setListings', { listings })
          : commit('concatListings', { listings })
      } else {
        commit('setListings', { listings })
        commit('filterBy/setAggs', { aggs })
      }

      if (total) commit('setTotal', { total })

      const counts = aggs.sold && aggs.sold.buckets.length > 0
      if (counts && !isScroll) {
        const forSale = aggs.sold.buckets.filter((b) => b.key === 0)
        const onSale = forSale && forSale.length > 0 ? forSale[0].doc_count : 0
        commit('setOnSale', onSale)
      }

      return listings
    } catch (err) {
      commit('setListings', { listings: [] })
      if (err?.response?.status !== 401) throw err
    } finally {
      commit('toggleLoading')
    }
  }
}
