export default () => ({
  aggs: null,
  activeMenu: null,
  values: {
    colour: [],
    year: [],
    dealer: [],
    listing: [],
    mileage: [],
    keyword: []
  }
})
