import * as companion from 'modules/companion/services'

export default {
  colour: state => companion.getColourTypes(state.aggs),
  listing: state => companion.getListingTypes(state.aggs),
  year: state => companion.getYears(state.aggs),
  dealer: state => companion.getDealerTypes(state.aggs),
  filters: state => state.values,
  colourFilter: state => state.values.colour,
  yearFilter: state => state.values.year,
  listingFilter: state => state.values.listing,
  dealerFilter: state => state.values.dealer,
  mileageFilter: state => state.values.mileage,
  keywordFilter: state => state.values.keyword,
  active: (state, getters) => companion.getActiveFilters(state.aggs, getters)
}
