import baseState from './state'
import * as account from 'modules/account/services'

const clearAndFetch = 'companion/similarListings/clearAndFetch'

export default {
  async setLocalFilters({ state, commit }) {
    const hasState = Object.values(state.values).some(f => f.length)
    if (hasState) return
    const localFilters = account.getLocalStorage('companion', 'filters')
    if (localFilters) await commit('setFilters', localFilters)
  },
  updateLocalStorage({ state }) {
    account.setLocalStorage('companion', 'filters', state.values)
  },
  async updateFilter({ commit, dispatch }, payload) {
    commit('setFilter', payload)
    dispatch('updateLocalStorage')
    dispatch(clearAndFetch, {}, { root: true })
  },
  async clearKeyword({ commit, dispatch }, payload) {
    commit('clearKeyword', payload)
    await dispatch(clearAndFetch, {}, { root: true })
    dispatch('updateLocalStorage')
  },
  async clearFilters({ commit }) {
    account.clearLocalStorage('companion', 'filters')
    commit('clearFilters', baseState().values)
  }
}
