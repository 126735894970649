import * as account from 'modules/account/services'

const initialState = () => {
  return {
    location: {
      method: '',
      postcode: '',
      distance: 5,
      coords: {
        lat: '',
        lon: ''
      }
    }
  }
}

export default {
  namespaced: true,
  state: initialState,
  getters: {
    location: state => state.location
  },
  mutations: {
    setLocationFromStorage(state) {
      state.location = account.getLocalStorage('companion', 'location')
    },
    saveLocationToStorage(state) {
      account.setLocalStorage('companion', 'location', state.location)
    },
    clearLocation(state) {
      const s = initialState()
      Object.keys(s).forEach(key => {
        state[key] = s[key]
      })
      account.clearLocalStorage('companion', 'location')
    },
    setCoords(state, payload) {
      state.location.coords = payload.coords
    },
    setMethod(state, payload) {
      state.location.method = payload.method
    },
    setDistance(state, payload) {
      state.location.distance = payload.distance
    },
    setPostcode(state, payload) {
      state.location.postcode = payload.postcode
    }
  },
  actions: {}
}
