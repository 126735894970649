export default {
  listings: state => state.listings,
  scroll: state => state.config.scroll,
  total: state => state.config.total,
  onSale: state => state.onSale,
  sortBy: state => state.sortBy,
  loading: state => state.loading,
  request: (state, getters, rootState, rootGetters) => {
    return {
      cid: rootGetters['companion/cid'],
      location:
        rootGetters['companion/similarListings/filterBy/distance/location'],
      mileage: rootGetters['companion/mileage'],
      order: state.sortBy.order,
      scroll: state.config.scroll,
      sort: state.sortBy.type,
      filter: getters['filterBy/filters'],
      value: rootGetters['companion/retailValue'],
      vrm: rootGetters['companion/vrm'],
      year: rootGetters['companion/year'],
      vin: rootGetters['companion/vin'],
      dealerIds: rootGetters['auth/dealerIds']
    }
  }
}
