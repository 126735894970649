import { isObject } from 'lodash'

export default {
  setOverview(state, data) {
    state.data.overview = data
  },
  setSpecs(state, data) {
    state.data.specs = data
  },
  setCustomerReference(state, data) {
    state.data.customerReference = data
  },
  setListing(state, data) {
    state.data.listing = data
  },
  setYoungUsed(state, youngUsed) {
    const isSet = isObject(state.data.value)
    if (isSet) state.data.value.youngUsed = youngUsed
    else state.data.value = { youngUsed }
  },
  setTimeline(state, data) {
    state.data.timeline = data
  },
  setImage(state, data) {
    state.data.image = data
  },
  setSection(state, section) {
    state.section = section
  },
  toggleOverlay(state, id) {
    state.overlay[id] = !state.overlay[id]
  },
  toggleLoading(state) {
    state.loading = !state.loading
  },
  reset: (state, data) => Object.assign(state, data)
}
