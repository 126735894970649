import { cz, node } from '@/plugins/axios'

import state from './state'

export default {
  changeView({ commit }, id) {
    commit('setSection', id)
    commit('toggleOverlay', 'nav')
  },
  async getOne({ commit, dispatch }, uuid) {
    dispatch('reset')
    commit('toggleLoading')
    try {
      const { data } = await node.get(`/companion/${uuid}`)
      if (data.response && data.response.valuation) {
        const { valuation, specs, listing, options } = data.response
        commit('setOverview', valuation)
        commit('setSpecs', specs)
        commit('setListing', listing)
        commit('setCustomerReference', data.request.customerReference)
        if (options) {
          const parsedOptions = JSON.parse(options.response)
          commit('options/setOptions', parsedOptions)

          if (options.createdAt)
            commit('options/setCreatedAt', options.createdAt)
        }
      }
      dispatch('getImage')
    } catch (err) {
      dispatch('reset')
      throw err
    } finally {
      commit('toggleLoading')
    }
  },
  async getTimeline({ commit }, uuid) {
    try {
      const { data } = await node.get(`/companion/${uuid}/timeline`)
      if (!data.response?.vin) return commit('setTimeline', false)
      commit('setTimeline', data.response)
    } catch (err) {
      commit('setTimeline', false)
      const timeout =
        err?.response?.status === 408 || err.code === 'ECONNABORTED'
      if (!timeout) throw err
    }
  },
  async getImage({ commit, getters }) {
    try {
      const { data } = await cz.get(`/image`, {
        params: {
          vrm: getters.vrm
        }
      })

      commit('setImage', data.image)
    } catch {
      commit('setImage', [])
    }
  },
  reset({ commit }) {
    commit('reset', state())
    commit('options/reset')
  }
}
