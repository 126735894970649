import * as companion from 'modules/companion/services'

import { getFullYear } from '@/utils/date.utils.ts'
import { isEmpty } from 'lodash'

export default {
  data: (state) => state.data,
  overview: (state) => state.data?.overview,
  vin: (state, { overview }) => overview?.vin,
  vrm: (state, { overview }) => overview?.searchMethod?.identifier,
  cid: (state, { overview }) => overview?.cid,
  mileage: (state, { overview }) => overview?.mileage?.value || null,
  namaGrade: (state, { overview }) => overview?.namaGrade,
  valuationMileage: (state, { overview }) => overview?.mileage,
  valuationDate: (state, { overview }) => overview?.date,
  retailValue: (state, { overview }) => overview?.retail,
  marketDemand: (state, { overview }) => overview?.marketDemand,
  stockingDepreciation: (state, { overview }) => overview?.stockDep,
  daysToSale: (state, { overview }) => {
    const valuation = overview?.daysToSale
    return valuation ? Math.round(valuation) : 43
  },
  youngUsed: (state, { overview }) => overview?.youngUsed,
  listing: (state) => state.data?.listing,
  pricePosition: (state, { listing }) => {
    return companion.getPricePosition({ listing })
  },
  pdfValues: (state, { cid, mileage, vrm, vin, year }) => {
    return { cid, mileage, vrm, vin, year }
  },
  timeline: (state) => state.data?.timeline,
  description: (state, { overview }) => overview?.description,
  photos: (state) => state.data?.image,
  specs: (state) => state.data?.specs,
  customerReference: (state) => state.data?.customerReference,
  make: (state, { specs }) => specs?.make,
  model: (state, { specs }) => specs?.model,
  trim: (state, { specs }) => specs?.trim,
  year: (_state, { specs }) => {
    return specs?.year || specs?.firstRegistration
      ? getFullYear(specs.firstRegistration)
      : null
  },
  type: (state, { specs }) => specs?.type ?? null,
  currentSection: (state) => state.section,
  isOverlayNav: (state) => state.overlay.nav,
  isOverlayMileage: (state) => state.overlay.mileage,
  isOverlayCondition: (state) => state.overlay.condition,
  loading: (state) => state.loading,
  isOutdated: (state) => isEmpty(state.data?.specs)
}
