export default () => ({
  section: 'overview',
  data: {
    overview: null,
    specs: null,
    timeline: null,
    value: null,
    listing: null,
    image: null
  },
  overlay: {
    nav: false,
    mileage: false,
    condition: false
  },
  loading: false
})
