import { getOptions } from 'modules/companion/services'

export default {
  namespaced: true,
  state: {
    options: null,
    createdAt: null
  },
  getters: {
    optionsData: state => state.options,
    createdAt: state => state.createdAt,
    vin: (state, getters, rootState, rootGetters) =>
      rootGetters['companion/vin'],
    vrm: (state, getters, rootState, rootGetters) =>
      rootGetters['companion/vrm'],
    description: (state, getters, rootState, rootGetters) =>
      rootGetters['companion/description'],
    make: (state, getters, rootState, rootGetters) =>
      rootGetters['companion/make']
  },
  mutations: {
    setOptions(state, data) {
      state.options = data
    },
    setCreatedAt(state, data) {
      state.createdAt = data
    },
    reset(state) {
      state.options = null
      state.createdAt = null
    }
  },
  actions: {
    async getOptions({ commit, getters }) {
      const { vin, vrm } = getters
      const { response, createdAt } = await getOptions({ vin, vrm })

      if (!createdAt) return commit('setOptions', false)

      commit('setCreatedAt', createdAt)
      commit('setOptions', JSON.parse(response))
    }
  }
}
