export default {
  setListings(state, payload) {
    state.listings = payload.listings
  },
  concatListings(state, payload) {
    state.listings = state.listings.concat(payload.listings)
  },
  setScroll(state, payload) {
    state.config.scroll = payload.scroll
  },
  setTotal(state, payload) {
    state.config.total = payload.total
  },
  setOnSale(state, payload) {
    state.onSale = payload
  },
  setSortBy(state, payload) {
    state.sortBy = payload.sortBy
  },
  toggleLoading(state) {
    state.loading = !state.loading
  }
}
