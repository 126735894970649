import similarListings from './similar-listings'
import options from './options'
import state from './companion/state'
import getters from './companion/getters'
import mutations from './companion/mutations'
import actions from './companion/actions'

export default {
  namespaced: true,
  modules: { similarListings, options },
  state: state(),
  mutations,
  getters,
  actions
}
