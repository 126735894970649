<template>
  <div>
    <div class="hero is-fullheight-with-navbar" v-if="!vrm">
      <div class="hero-body">
        <div class="container">
          <Spinner />
        </div>
      </div>
    </div>
    <div v-else>
      <section class="section">
        <div class="container">
          <div class="columns">
            <div class="column is-4-desktop is-6">
              <VehicleCarousel
                v-bind="{
                  vrm,
                  cid,
                  photos,
                  hasAutoCheck,
                  precheck: $wait.is('precheck'),
                  showCheck: !isIntegration
                }"
                @check="autocheck"
              />
              <CompanionValuationActions />
            </div>
            <div class="column">
              <CompanionOverview />
            </div>
          </div>
        </div>
      </section>
      <section class="section">
        <div class="container">
          <CompanionContent />
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import store from '../stores'
import registerModule from '@/store/services'
import { mapGetters, mapState, mapActions } from 'vuex'
import { isEmpty } from 'lodash'

import * as autocheck from 'modules/autocheck/services'
export default {
  name: 'Companion',
  components: {
    Spinner: () => import('core/components/Spinner'),
    CompanionOverview: () =>
      import('modules/companion/components/section/CompanionOverview'),
    CompanionValuationActions: () =>
      import('modules/companion/components/section/CompanionValuationActions'),
    CompanionContent: () =>
      import('modules/companion/components/section/CompanionContent'),
    VehicleCarousel: () =>
      import('core/modules/vehicle-carousel/components/VehicleCarousel')
  },
  data: () => ({ visible: true }),
  computed: {
    ...mapState('overlay', ['mobNav']),
    ...mapGetters('companion', [
      'data',
      'vrm',
      'make',
      'model',
      'cid',
      'photos',
      'specs',
      'overview',
      'listing',
      'pricePosition',
      'type',
      'timeline'
    ]),
    ...mapGetters('auth', ['permissions', 'hasAutoCheck', 'isIntegration']),
    showListingModule() {
      return this.permissions.currentListing && !isEmpty(this.listing)
    }
  },
  watch: {
    $route: 'getVehicle'
  },
  async created() {
    registerModule('companion', store)
    await this.getVehicle()
  },
  destroyed() {
    this.reset()
    this.clearFilters()
  },
  methods: {
    ...mapActions('companion', ['getOne', 'reset']),
    ...mapActions('companion/similarListings/filterBy', ['clearFilters']),
    async getVehicle() {
      const { uuid } = this.$route.params
      try {
        await this.getOne(uuid)
      } catch (err) {
        this.$notify("Sorry, we can't retrieve your valuation right now")
        if (this.$route.name !== 'root')
          this.$router.push({ name: 'root' }).catch(() => {})
      }
    },
    async autocheck() {
      this.$mxp.track('companion_carousel_check')
      this.$wait.start('precheck')
      try {
        const data = await autocheck.precheck({
          value: this.vrm,
          type: 'vrm'
        })

        this.$modal.open('autocheck/AutoCheckOptions', {
          vrm: data.vrm,
          vin: data.vin,
          description: autocheck.buildPreDescription(data),
          mileage: data.mileage
        })
      } catch (err) {
        this.$mxp.track('companion_carousel_precheck_failed')
        const msg = err?.response?.data?.error?.message
        this.$notify(msg || 'Currently unable to perform a check')
      } finally {
        this.$wait.end('precheck')
      }
    }
  }
}
</script>
